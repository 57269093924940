/* #region koders font */
@font-face {
  font-family: koders;

  src: url(./assets/fonts/Poppins-Bold.ttf);

  font-weight: 700;
}

@font-face {
  font-family: koders;
  src: url(./assets/fonts/Poppins-Light.ttf);
  font-weight: 300;
}

/* #endregion */

* {
  padding: 0;
  margin: 0;
}

.koders {
  background-color: #eee;
  color: #333;
  /* background-color: #282828; */
  /* background: rgb(255,104,104); */
  /* background: linear-gradient(90deg, rgba(255,104,104,1) 0%, rgba(252,169,14,1) 100%); */
  /* color: #eee; */
  font-family: koders;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
}

.koders-icon {
  width: 10rem;
}

.logo {
  position: relative;
}

.logo .background {
  fill: #fca50e;
}

.logo .letter {
  fill: #282828;
}

.koders h2 {
  letter-spacing: 0.075rem;
  font-weight: 700;
  /* line-height: 1; */
}

.koders p {
  /* line-height: 1; */
  letter-spacing: 0.05rem;
  font-weight: 300;
}
